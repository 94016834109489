<template>
  <div class="myvideo">
    <van-nav-bar
      title="我的视频"
      left-arrow
      fixed
      @click-left="onClickLeft"
    />
    <div class="content">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div class="main_list" v-if="list && list.length > 0">
            <van-grid :column-num="2" :border="false">
              <van-grid-item v-for="(item, index) in list" :key="index" @click="detail(index)">
                <div class="list_miaoshu" :style="'width: '+((screenWidth-32)/2)+'px;height: '+((screenWidth-32)/2/315*230)+'px;'">
                  <van-image
                    v-if="item.thumb && item.thumb.length > 10"
                    :src="item.thumb"
                    height="100%"
                    width="100%"
                    fit="contain"
                  >
                    <template v-slot:loading>
                      <van-loading type="spinner" size="20" />
                    </template>
                  </van-image>
                  <div class="list_text">
                    <div class="list_item_icon" :style="'height: 30px;width:30px;top: '+(((screenWidth-30)/2/315*230-50-30)/2)+'px;'">
                      <van-icon :name="item.isSalse == 1 ? 'lock' : 'play'" size="30" color="#fff" />
                    </div>
                    <div class="list_text_item1">
                      <van-tag v-if="item.status == 0" plain :type="item.status == 0 ? 'warning' : ''">审核中</van-tag>
                      <van-tag v-if="item.status == 1" plain :type="item.status == 1 ? 'success' : ''">审核通过</van-tag>
                      <van-tag v-if="item.status == -1" plain :type="item.status == -1 ? 'danger' : ''">审核未通过</van-tag>
                      精确引流绝技
                    </div>
                    <div class="list_text_item2">云店商学院 · {{item.views}}人已学习</div>
                  </div>
                </div>
              </van-grid-item>
            </van-grid>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <van-tabbar v-model="active" active-color="#000" inactive-color="#333">
      <van-tabbar-item @click="goHome">首页</van-tabbar-item>
      <van-tabbar-item @click="addvideo" v-show="showaddbtn"><van-button plain size="mini" icon="plus"></van-button></van-tabbar-item>
      <van-tabbar-item @click="myvideo">我的</van-tabbar-item>
    </van-tabbar>

    <!-- 评论弹框 -->
    <van-popup
      position="bottom"
      v-model="pinglunShow"
      get-container=".myvideo"
      style="height:80%; overflow-x:hidden; overflow-y: auto;"
      closeable
      round
    >
      <div class="pinglun_main_title">
        <van-cell title-class="pinglun_title">
          <template #title>
            <div>
              留言评论
            </div>
          </template>
        </van-cell>
      </div>
      <div class="pinglun_list" :style="'max-height:' + (screeHeight * 0.8 + 60 - 130) + 'px;'">
        <van-list
          v-model="pinglunloading"
          :finished="pinglunfinished"
          finished-text="没有更多了"
          @load="pinglunonLoad"
          :immediate-check="false"
        >
          <van-cell-group :border="false" v-for="(item, index) in pinglunlist" :key="index">
            <van-cell>
              <template #icon>
                <van-image
                  round
                  width="40px"
                  height="40px"
                  :src="item.avatar"
                  fit="cover"
                />
              </template>
              <template #title>
                <div class="van-ellipsis">{{item.nickname}}</div>
                <div>{{item.content}}</div>
              </template>
              <template #label>
                <span style="margin-right: 10px;">{{item.createtime_str}}</span>
              </template>
            </van-cell>
          </van-cell-group>
        </van-list>
      </div>
      <div class="poinglun_input van-hairline--top">
        <van-field
          v-model="inputmsg"
          :type="inputType"
          placeholder="留下您的精彩评论吧"
          :autosize="{ maxHeight: 72, minHeight: 24 }"
        >
          <template #right-icon>
            <button @click="sendMsg" :loading="btnloading" v-if="inputmsg" size="small" type="danger" plain>发布</button>
          </template>
        </van-field>
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  name: "Myvideo",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      loading: false, // 本次加载是否完成
      finished: false, // 是否加载完毕（全部加载完成）
      currSize: 10, // 步数
      currOffset: 0, // 偏移
      shareid: 0,
      merchid: 0,
      active: 1,
      list: [], // 列表
      refreshing: false, // 下拉刷新是否结束
      pinglunShow: false,
      inputType: 'textarea', // 默认是text
      inputmsg: '', // 输入内容
      pinglunfinished: false, // 默认评论加载完成
      pinglunloading: false, // 默认评论本次加载完成
      pinglunSize: 20, // 默认评论本次加载完成
      pinglunOffset: 0, // 默认评论本次加载完成
      pinglunlist: [], // 评论列表
      videoinfo: {}, // 目前是哪个视频的留言
      btnloading: false, // 是否执行中
      msgid: 0, // 默认消息id
      userinfo: {}, // 用户信息
      showaddbtn: false, // 默认不显示添加按钮
    }
  },
  mounted() {
    this.getinfor(); // 获取用户信息
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "视频列表");
  },
  methods: {
    // 获取会员信息
    getinfor() {
      let _that = null;
      _that = this;
      const jwtStr = localStorage.getItem("DK_UID")
        ? localStorage.getItem("DK_UID")
        : ""; // 公钥
      _that.$axios.defaults.headers.common['Authorization'] = jwtStr;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(_that.$store.state.domain + "web/user/getinfor")
        .then(function(response) {
          if (response.data) {
            _that.userinfo = response.data;
            if(_that.userinfo.level1_white==1){
              _that.showaddbtn = true;
            }
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    onLoad() {
      console.log("onLoad");
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/videos/get_myvideo",
          _that.$qs.stringify({
            offset: _that.currOffset,
            size: _that.currSize
          })
        )
        .then(res => {
          console.log(res);
          _that.refreshing = false;
          _that.loading = false; // 本次加载是否完成
          if (res.data.code == 100000) {
            if (_that.currOffset == 0) {
              _that.list = res.data.data;
            } else {
              res.data.data.forEach(item => {
                _that.list.push(item);
              })
            }
            if (res.data.data.length >= _that.currSize) {
              _that.currOffset += _that.currSize;
            } else {
              _that.currOffset += res.data.data.length;
              _that.finished = true; // 是否加载完毕（全部加载完成）
            }
          }else{
            _that.finished = true; // 是否加载完毕（全部加载完成）
          }
        })
        .catch(err => {
          _that.finished = true; // 是否加载完毕（全部加载完成）
          console.log(err);
        })
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back();
    },
    goHome() {
      this.$router.replace({ name: "Home", query: {shareid: this.shareid, merchid: this.merchid, _t: 3}});
    },
    addvideo() {
      console.log("发布页面");
      this.$router.push({name: "VideoAdd", query: {shareid: this.shareid, merchid: this.merchid}});
    },
    myvideo() {
      console.log("视频管理页");
      this.$router.push({name: "Myvideo", query: {shareid: this.shareid, merchid: this.merchid}});
    },
    // 下拉刷新
    onRefresh() {
      this.currOffset = 0;
      this.list = [];
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },
    // 评论
    liuyan (index) {
      console.log(index);
      this.pinglunShow = true;
      this.videoinfo = this.list[index];// 视频info
      this.pinglunOffset = 0;
      this.pinglunlist = [];
      this.pinglunonLoad();
    },
    // 分享
    fenxiang(index) {
      console.log(index);
    },
    // 删除
    shanchu(index) {
      console.log(index);
      let _that = null;
      _that = this;
      this.$dialog.alert({
        message: "确认要删除该视频？",
        showCancelButton: true,
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        beforeClose: function (action, done) {
          if (action == "confirm") {
            _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
            _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
            _that.$axios
              .post(
                _that.$store.state.domain + "web/videos/del_video",
                _that.$qs.stringify({
                  id: _that.list[index].id
                })
              )
              .then(res => {
                console.log(res);
                if (res.data.code == 100000) {
                  _that.$toast(res.data.msg ? res.data.msg : "删除成功");
                  _that.list.splice(index, 1);
                }else {
                  _that.$toast(res.data.msg ? res.data.msg : "删除失败");
                }
              })
              .catch(err => {
                console.log(err);
              })
          }
          done();
        }
      });
    },
    // 获取评论内容
    pinglunonLoad() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/videos/get_msg",
          _that.$qs.stringify({
            videoid: _that.videoinfo.id,
            size: _that.pinglunSize,
            offset: _that.pinglunOffset
          })
        )
        .then(res => {
          console.log(res);
          _that.pinglunloading = false;
          if (res.data.code == 100000) {
            if (_that.pinglunOffset == 0) {
              _that.pinglunlist = res.data.data;
            }else{
              res.data.data.forEach(item => {
                _that.pinglunlist.push(item);
              })
            }
            
            if(res.data.data.length >= _that.pinglunSize) {
              _that.pinglunOffset += _that.pinglunSize;
              _that.pinglunfinished = false;
            }else {
              _that.pinglunOffset += res.data.data.length;
              _that.pinglunfinished = true;
            }
          }else{
            _that.pinglunfinished = true;
          }
        })
        .catch(err => {
          _that.pinglunloading = false;
          _that.pinglunfinished = true;
          console.log(err);
        })
    },
    // 发表评论
    sendMsg() {
      let _that = null;
      _that = this;
      
      if(!_that.btnloading) {
        _that.btnloading = true;
        _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/videos/add_msg",
            _that.$qs.stringify({
              videoid: _that.videoinfo.id,
              msgid: _that.msgid,
              msg: _that.inputmsg
            })
          )
          .then(res => {
            _that.btnloading = false;
            console.log(res);
            if (res.data.code == 100000) {
              _that.$toast("提交成功");
              _that.inputmsg = "";
              _that.pinglunOffset = 0;
              this.pinglunonLoad();
            }
          })
          .catch(err => {
            _that.btnloading = false;
            console.log(err);
          })
      }
    },
    // 分享初始化
    peizhiWxConfig() {
      let _that = null;
      _that = this;

      /* 获取wx初始化配置 */
      _that.$axios
        .post(
          _that.$store.state.domain + "web/wetch/wx_sign",
          _that.$qs.stringify({
            url: encodeURIComponent(window.location.href)
          })
        )
        .then(function(response) {
          _that.wxConfig.timestamp = response.data.timestamp;
          _that.wxConfig.nonceStr = response.data.nonceStr;
          _that.wxConfig.signature = response.data.signature;
          window.wx.config(_that.wxConfig); // wx初始化配置
        })
        .catch(function(error) {
          console.log(error, "error");
          window.getWx = _that.getWxConfig; // 微信签名过期后调用 外部JS
        });
    },
    /* 获取配置失效 */
    getWxConfig(err) {
      // 如果过期，从html JS 调用重新签名
      if (err.indexOf("invalid signature") != -1) {
        let $this = null;
        $this = this;
        this.$axios
          .post(
            $this.$store.state.domain + "web/wetch/wx_sign",
            $this.$qs.stringify({
              url: encodeURIComponent(window.location.href)
            })
          )
          .then(function(response) {
            console.log(response);
            this.peizhiWxConfig(); // 配置微信分享
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    },
    videoplayer(index){
      let _that = null;
      _that = this;

      _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/videos/set_views",
          _that.$qs.stringify({
            id: _that.list[index].id
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.list[index].views = res.data.num;
          }
        })
        .catch(err => {
          console.log(err);
        })
    }
  },
}
</script>
<style lang="less">
.myvideo {
  width: 100%;
  min-height: 100%;
  position: relative;
  z-index: 2;
  overflow: hidden;
  // 顶部标签
  .van-nav-bar .van-icon,
  .van-nav-bar__text,
  .van-nav-bar__title {
    color: #333;
  }
  // 视频列表
  .content {
    padding: 60px 0;
    overflow: hidden;
    width: 100%;
    .main_list {
      padding-left: 10px;
      .van-grid-item__content {
        padding: 0;
        margin: 10px 10px 0 0;
      }
      .list_miaoshu {
        font-size: 12px;
        color: #333;
        overflow: hidden;
        border-radius: 10px;
        position: relative;
      }
      .list_item_icon {
        position: absolute;
        top: 10px;
        left: 50%;
        margin-left: -15px;
      }
      .list_text {
        position: absolute;
        background-color: rgba(0,0,0,0.5);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
      .list_text_item1,
      .list_text_item2 {
        height: 20px;
        font-size: 12px;
        color: #fff;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        position: absolute;
        left: 10px;
        right: 10px;
        text-align: left;
      }
      .list_text_item1 {
        bottom: 30px;
      }
      .list_text_item2 {
        bottom: 10px;
      }
    }
  }
  // 底部菜单
  .van-tabbar {
    color: #333;
    .van-tabbar-item {
      font-size: 16px;
      .van-button {
        color: #333;
        padding: 5px 10px;
        border-width: 2px;
        border-radius: 6px;
        font-size: 12px;
        font-weight: bold;
      }
    }
  }

  // 评论样式
  .van-popup {
    padding-bottom: 60px;
    .pinglun_main_title {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      overflow: hidden;
      .pinglun_title {
        flex: 1;
        margin-top: 5px;
      }
    }
    .pinglun_list {
      overflow-x: hidden;
      overflow-y: auto;
      height: 100%;
      margin: 60px 0 0;
      .van-cell {
        padding: 10px 10px 0;
        &:last-of-type {
          padding-bottom: 10px;
        }
        .van-image {
          margin-right: 10px;
        }
        .van-cell__title {
          flex: 1;
          text-align: left;
          overflow: hidden;
        }
      }
    }
    .poinglun_input {
      position: fixed;
      left: 0;
      bottom: 0;
      right: 0;
      .van-field {
        .van-field__value {
          flex: 1;
        }
        .van-field__right-icon {
          button {
            color: #ee0a24;
            border-color: #ee0a24;
            background-color: #fff;
            border-width: 1px;
            font-size: 14px;
            line-height: 20px;

          }
        }
      }
    }
  }
}
</style>